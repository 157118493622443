<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-28 17:26:41
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-21 11:10:40
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\Brief\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="page-main home-main">
      <SiteTopbar/>
 
  <div class="row">
      <div class="headerimg">
        <div class="headerss">
       
        
        <div class="header-text">
          <p style="font-size:2rem">让制造业的每一份创造都被看见</p>
          <p style="font-size:1.5rem">every creation in manufacturing Will be seen</p>
        <div class="header-img">
          <img src='../../assets/imgs/TKK/tunine.png' width="90vw" height="100vh" >
        </div>
        <div>
        
        </div>
        </div>
       
        </div>
      </div>
  </div>
   <div class="container">
      <div class="cont-title" >
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
          <p>  公司简介  </p>
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
      </div>
      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>深圳市小秤砣科技有限公司</p>
      </div>
      <div class="content">
          <p>秤砣虽小压千斤，也象征公平，秤的是人心。
              <br/>小秤砣，旨在用大数据、AI、云计算、物联网新型技术搭建一个集工程技术、制造企业、研究机构和制造从业者及相关人员的产业聚合平台，一起推动制造转向智造，打造工业互联网平台。
              <br/>小秤砣，将用代码驱动铜、铝、PC、ABS、IC、电容.....原材料的高效流动，用图形算法实现更合理的价值匹配，用3D技术展示产品的全域属性，用字符、公式为各方人员沟通交流搭起一座桥。
              <br/>小秤砣，倡导马拉松精神，坚持长期主义，为制造业持续创造价值的理念，跟时间做朋友，做难而正确的事。</p>
      </div>
 
        <div class="cont-title">
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
          <p>  企业文化  </p>
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
      </div>
      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>使命</p>
      </div>
      <div class="content">
          <p class="content-span">让制造业的每一份创造都被看见。</p>
            <p>制造业乃社会基石，所有跟人们美好生活相关的，回归本质都需要被制造，所以制造业默默的每一份创造都应该被看见，都值得被更多的看见。</p>  
      </div>

      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>价值观</p>
      </div>
      <div class="content">
          <p class="content-span">小企业、大创造、有价值、可尊敬。</p> 
            <p>小秤砣虽然还很小，但我们坚信我们的创造会给制造业，给社会带去价值，我们要坚守正确的价值观，让小秤砣是可以被尊敬的小企业。 </p>
      </div>

       <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>愿景</p>
      </div>
      <div class="content">
          <p class="content-span">智造未来，联接世界。</p>  
            <p>制造是现在，智造是未来，中国智造会走向世界，会联接世界。</p>  
      </div>

             <div class="cont-title">
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
          <p>  办公环境  </p>
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
      </div>


      <div class="contemt-img">
        <div class="contemt-imgs">
          <img src="../../assets/imgs/TKK/14.jpg">
        </div>
        <div class="contemt-imgs">
          <img src="../../assets/imgs/TKK/05.jpg" >
        </div>
        <div class="contemt-imgs">
          <img src="../../assets/imgs/TKK/28.jpg" >
        </div>
        <div class="contemt-imgs">
          <img src="../../assets/imgs/TKK/04.jpg" >
        </div>
      </div>
       <div class="cont-title">
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
          <p>  公司地址  </p>
          <img src="../../assets/imgs/TKK/tuten.png" width="24px" height="24px">
      </div>


    </div>
      <div class="content-map">
          <div id="maps">
             <img src="../../assets/imgs/TKK/maps.png" >
          </div>
          <div class="maps-text">
                <p>地址：深圳市光明区马田街道合水口社区中粮云景广场第1栋</p>
                <p>邮箱：op@smallweight.com</p>
                <p>电话：0755-86902867</p>
          </div>
      </div>
          <Footer/>
          <Info/>
          <Cebian/>

    </div>
  
</template>

<script>
import SiteTopbar from '@/components/header/SiteTopbar'
import Footer from '@/components/PageMain/Bottom/Footer'
import Info from '@/components/PageMain/Bottom/Info'
import Cebian from '@/components/Cebian';

export default {
 name: 'brief',
  components:{
    SiteTopbar,
    Footer,
    Info,
    Cebian
  },
     data(){
    return{
      isOverWX: false,
    
    }
  },
  methods: {

    brief(){
     
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
    created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')

      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  mounted(){
    this.brief()
  }
}
</script>

<style >
/* .home-main {
  padding-top: 4px;
  /* padding-bottom: 12px; 

} */
.page-main {
  /* background: #ffffff; */
   
}
.container {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}
.row{

  width: 100%;
  position: relative;
}
 .headerimg{
  width: 100%;
  background-image: url('../../assets/imgs/TKK/tueight.png');
  background-repeat:no-repeat;
  height: 70vh;
  background-size: 100%;
 }
 .header-text{

/* margin-top: 50px; */
padding-top: 50px;
 text-align: center;
   line-height: 50px;
color:#fff;
 }
 /* .header-text p{
   text-align: center;
   line-height: 50px;
 } */
 .cont-title{
   margin-top: 10vh;
   display: flex;
  justify-content: center;
   height: 60px;
   align-items: center;
   
 }
 .cont-title p{
  font-weight: 700;
   color: rgba(69, 77, 89, 1);
   font-size: 35px;
   border-bottom: 1px solid rgba(43, 95, 255, 1);
   margin-left: 10px;
   margin-right: 10px;

 }
 .content-title{
   margin-top: 100px;

   height: 50px;

   background-size: 100%;
   background-position:left;
 }
 .content-title p{
   position: relative;
   top: -26px;
   left: 10px;
   color: rgba(69, 77, 89, 1);
font-size: 28px;
font-weight: 700;

 }
 .content{

padding-top: 25px;
color: rgba(69, 77, 89, 1);
font-size: 20px;
line-height: 45px;
font-weight: 400;
 }
 .contemt-img{
   margin-top: 100px;
   display: flex;
   justify-content: space-between;
   width: 100%;
   
 }
 .content-map{
   margin-top: 50px;
   margin-bottom: 13vh;
  /* height: 80vh; */
/* background: linear-gradient(135deg, rgba(61, 90, 255, 1) 0%, rgba(69, 156, 255, 1) 100%); */
 }
 #maps{
     width: 60%;
   margin-left: auto;
   margin-right: auto;
    display: flex;
    justify-content: center;
    
 }
 #maps img{
  margin-top: 50px;
   width: 80%;
   padding: 5px;
   border: 3px solid #f4f4f4;
   border-radius: 10px;
 }
 .maps-text{
  width: 30%;
   margin-left: auto;
   margin-right: auto;
   z-index: 1;

    padding: 10px;
    border-radius: 25px 0px 25px 0px;
    position: relative;
    top: -50px;
   background: #ececec;
 }
 .maps-text p{
   color: rgba(69, 77, 89, 1);
font-size: 18px;
font-weight: 400;
 }
  .contemt-imgs{
width: 100%;
  }
 .contemt-imgs img{
    width:90%;
     height:100%;
   border-radius: 20px;
    transition: 0.5s;
 }
 .contemt-imgs img:hover{
   transform:scale(1.1,1.1);
 }
 .headerss{

   width: 40%;
   margin-left: auto;
   margin-right: auto;
 }
 .content-span{
   font-weight: 600;
 }
 .header-img{

   height: 20vh;
   margin-top: 5%;
   display: flex;
   align-items: center;
   text-align: left;
 }
</style>